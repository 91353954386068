import * as React from "react"
import "../styles/normalize.css"
import "../styles/skeleton.css"
import "../styles/style.scss"
import Menu from './menu.js'


// markup
export default function Layout({children}) {
  return (
<React.Fragment>
<Menu />
<main id="main">
	{children}
 </main>
 </React.Fragment>
)}
// import * as React from "react"

import { Link } from "gatsby"
import React, { Component } from 'react';
//import {ReactComponent as MenuIcon} from './menu.svg'
    
//the class you are making your component from
class Menu extends Component {
  // constructor to set state and bind "this"
  constructor(props) {
      super(props);
      this.state = {bodyOpen: false};
      this.toggleMenu = this.toggleMenu.bind(this);
    }

  // function to handle the click
   toggleMenu(e) {
	
	this.setState(prevState => ({
      bodyOpen: !prevState.bodyOpen
    }));
  	document.body.classList.toggle('open');
	if(e.currentTarget.id === "menu")  {
		e.preventDefault();
	}
	 else {
	 	if(document.location.pathname === "/"){
		 	e.preventDefault();
			const href = e.currentTarget.getAttribute("href");
			console.log(href);
			let offsetTop = document.querySelector(href.substr(1,href.length)).offsetTop;
			window.scroll({
				top: offsetTop,
				behavior: "smooth"
			});
		}
	}
  	
  }
  
  // the render() method to put stuff into the DOM
  render() {
    
    // the return() to put your default HTML into the DOM
    return (
        // wrapper div of component
        <React.Fragment>
			<a id="menu" href="#" onClick={this.toggleMenu}>
				<div className="menu-icon">
					<div></div>
					<div></div>
					<div></div>
				</div>
				<span>Menu</span>
			</a>
			<nav id="nav" className="sidenav">
			  <ul>
			  	<li><Link onClick={this.toggleMenu} to="/#hero">Home</Link></li>
			  	<li><Link onClick={this.toggleMenu} to="/#projects">Projects</Link></li>
			  	<li>External</li>
			  	 <li><a target="_blank" rel="noreferrer" href="https://codepen.io/barton_white">Codepen.io</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/bart-white/">LinkedIn</a></li>
			  </ul>
			</nav>
		</React.Fragment>
    );
  }
}
// export the class so you can call it elsewhere
export default Menu;

/*export default function Menu({children}) {
	this.state = {bodyOpen: false};

	function toggleMenu(e){
		if(e.currentTarget.id === "menu") e.preventDefault();
	  	document.body.classList.toggle('open');
	  	console.log(e.currentTarget.id);
	  	this.setState((prevState) => {
	  		bodyOpen: !prevState.bodyOpen
	  	});
	}

	return(
		<React.Fragment>
			<a id="menu" href="#" onClick={toggleMenu}>
				<MenuIcon />
				<span>Menu</span>
			</a>
			<nav id="nav" className="sidenav">
			  <ul>
			  	<li><a onClick={toggleMenu} href="#hero">Top</a></li>
			  	<li><a onClick={toggleMenu} href="#projects">Projects</a></li>
			  	<li><a onClick={toggleMenu} href="#links">Links</a></li>
			  	<li><a onClick={toggleMenu} href="#contact">Contact</a></li>
			  </ul>
			</nav>
		</React.Fragment>
	)
}*/